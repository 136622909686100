<template>
  <!-- 面经主页 -->
  <div id="app" style="background: #f4f4f4">
    <div class="company-banner" style="margin-bottom: 12px">
      <div class="company-banner-content">
        <div class="company-banner-title">
          <div class="company-banner-logo">
            <img src="../../assets/company/mj-logo.png" />
          </div>
          <span>面经</span>
        </div>
        <div class="company-banner-text">
          面经平台，初创不易，如有面试希望大家积极回馈，相互帮助
        </div>
      </div>
    </div>
    <interviewExp></interviewExp>
  </div>
</template>

<script>
import interviewExp from "./interviewExp";
export default {
  data() {
    return {
      input: "",
      select: "All",
    };
  },
  components: { interviewExp },
  methods: {
    handClick() {
      this.$router.push("/interviewEdit");
    },
  },
};
</script>

<style lang="css" scoped>
@import url("./interviewHome.css");
</style>
